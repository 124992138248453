import * as React from 'react';
import { Card, CardContent, CardHeader, Divider, Fab, IconButton, List } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import CreateSessionDialog from './CreateSessionDialog';
import { useSessionsOfJourneyData } from '../../_hooks/useJourney.hook';
import SessionListItem from './SessionListItem';
import EditSessionDialog from './EditSessionDialog';

interface ISessionsOfJourneyProps {
  journeyId: string;
}

export default function SessionsOfJourney(props: ISessionsOfJourneyProps) {
  const { journeyId } = props;
  const [open, setOpen] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [selectedSessionId, setSelectedSessionId] = React.useState<string | undefined>(undefined);
  const { data: sessions, refetch: refetchSessions } = useSessionsOfJourneyData(journeyId);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleEditSessionById = (sessionId: string) => {
    setSelectedSessionId(sessionId);
    setOpenEdit(true);
  };

  const handleCloseEdit = () => {
    setSelectedSessionId(undefined);
    setOpenEdit(false);
    refetchSessions();
  };

  return (
    <>
      <Card sx={{ margin: 1 }}>
        <CardHeader
          action={
            <Fab onClick={handleOpen} color="primary" size="medium" aria-label="add">
              <AddIcon />
            </Fab>
          }
          title="Sessions"
        />
        <CardContent>
          <List sx={{ pt: 0 }} dense={true}>
            <Divider />
            {sessions?.map((session) => (
              <div key={session.id}>
                <SessionListItem
                  session={session}
                  secondaryAction={
                    <>
                      <IconButton edge="end" aria-label="edit" onClick={() => handleEditSessionById(session.id)}>
                        <EditIcon />
                      </IconButton>
                    </>
                  }
                ></SessionListItem>
                <Divider />
              </div>
            ))}
          </List>
        </CardContent>
      </Card>
      <CreateSessionDialog journeyId={journeyId} open={open} onClose={handleClose} />
      {selectedSessionId ? (
        <EditSessionDialog id={selectedSessionId} open={openEdit} onClose={handleCloseEdit} />
      ) : null}
    </>
  );
}
