import { USER } from './../_helpers/app.constants';
import jwt_decode from 'jwt-decode';
import { IJwt } from '../_interfaces/jwt.interface';
import { IUser } from '../_interfaces/user.interface';
import { axiosClient } from '../_utils/axios-utils';

export const LOGIN_URL = '/auth/login';

export const login = (username: string, password: string) => {
  return axiosClient.post(LOGIN_URL, { username, password }).then((res: any) => {
    const { access_token } = res;
    if (access_token) {
      const jwt: IJwt = jwt_decode(access_token);
      const { surname, lastname, id, username, role } = jwt;
      const user: IUser = { surname, lastname, id, username, role, access_token };
      localStorage.setItem(USER, JSON.stringify(user));
    }
    return res;
  });
};

export const logout = () => {
  localStorage.removeItem(USER);
  window.location.reload();
};

export const getUser = (): IUser | undefined => {
  const userStr = localStorage.getItem(USER);
  if (userStr) {
    const obj = { ...JSON.parse(userStr), member: true };
    return obj;
  }
  return undefined;
};
