import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import { format } from "date-fns";
import { Alert, IconButton, Typography } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useJourniesData } from "../../_hooks/useJourney.hook";
import { removeJourney } from "../../_services/journey.service";
import { IJourney } from "../../_interfaces/journey.interface";

export default function JourneyList() {
  const { data: journies, refetch: refetchJournies } = useJourniesData();

  const handleDelete = (id: string) => {
    removeJourney(id).finally(() => {
      refetchJournies();
    });
  };

  const getSecondaryString = (journey: IJourney): string => {
    const { operatingHoursEnd, operatingHoursStart, startDate } = journey;
    const durationInMin = Math.round((operatingHoursEnd - operatingHoursStart) * 60);

    return `${format(new Date(journey.startDate), "dd.MM.yyyy")} - ${durationInMin} min`;
  };

  return journies?.length ? (
    <>
      <Typography variant="button" display="block" gutterBottom sx={{ marginLeft: 2, marginTop: 2 }}>
        Abgeschlossene Fahrten
      </Typography>
      <List sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}>
        {journies.map((journey, index) => (
          <div key={index}>
            <ListItem
              secondaryAction={
                <IconButton edge="end" aria-label="delete" onClick={() => handleDelete(journey.id)}>
                  <DeleteIcon />
                </IconButton>
              }
            >
              <ListItemAvatar>
                <Avatar>{journey.boat.number}</Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={
                  <div>
                    {journey.driver.surname} {journey.driver.lastname}
                  </div>
                }
                secondary={getSecondaryString(journey)}
              />
            </ListItem>
          </div>
        ))}
      </List>
    </>
  ) : null;
}
