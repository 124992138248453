import { ICreateSession } from '../_interfaces/create-session.interface';
import { ISession } from '../_interfaces/session.interface';
import { IUpdateSession } from '../_interfaces/update-session.interface';
import { axiosClient } from '../_utils/axios-utils';

export const SESSION_URL = '/session';

export const createSession = (session: ICreateSession): Promise<void> => {
  return axiosClient
    .post(SESSION_URL, session)
    .then(() => {
      return;
    })
    .catch((err) => {
      const { message } = err.response.data;
      throw new Error(message);
    });
};

export const updateSession = (session: IUpdateSession): Promise<void> => {
  const { id, paymentType, sessionType, amount } = session;
  return axiosClient
    .put(`${SESSION_URL}/${id}`, { paymentType, sessionType, amount })
    .then(() => {
      return;
    })
    .catch((err) => {
      const { message } = err.response.data;
      throw new Error(message);
    });
};

export const getSessionById = (id: string): Promise<ISession> => {
  return axiosClient.get(`${SESSION_URL}/${id}`);
};

export const removeSession = (id: string): Promise<void> => {
  return axiosClient
    .delete(`${SESSION_URL}/${id}`)
    .then(() => {
      return;
    })
    .catch((err) => {
      const { message } = err.response.data;
      throw new Error(message);
    });
};
