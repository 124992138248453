import { ISession } from "../../_interfaces/session.interface";
import { Chip, Badge, CardActions, CardContent, Collapse, List, Typography, styled, Grid } from "@mui/material";
import SessionListItem from "./SessionListItem";
import IconButton, { IconButtonProps } from "@mui/material/IconButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SurfIcon from "@mui/icons-material/Surfing";
import React from "react";

interface ISessionListProps {
  sessions: ISession[];
}

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function SessionListCollapse(props: ISessionListProps) {
  const { sessions } = props;
  const [sessionExpanded, setSessionExpanded] = React.useState(false);

  const handleSessionExpand = () => {
    setSessionExpanded(!sessionExpanded);
  };

  const getSessionSum = (): number => {
    if (!sessions.length) return 0;
    return sessions.reduce((partialSum: number, entry) => partialSum + entry.amount, 0);
  };

  return (
    <>
      <CardActions sx={{ paddingLeft: 3, minHeight: 50 }}>
        <Badge badgeContent={sessions?.length} color="secondary" showZero>
          <SurfIcon color="action" />
        </Badge>
        <Grid container justifyContent="space-between" alignItems="center">
          {/* <Grid item xs={8}></Grid>
            <Grid item xs={4}></Grid> */}
          <Typography sx={{ marginLeft: 3 }}>Sessions</Typography>
          <Chip label={getSessionSum() + " min"} variant="filled" color="primary" size="small" />
        </Grid>
        {sessions.length > 0 ? (
          <ExpandMore
            expand={sessionExpanded}
            onClick={handleSessionExpand}
            aria-expanded={sessionExpanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </ExpandMore>
        ) : null}
      </CardActions>
      <Collapse in={sessionExpanded} timeout="auto" unmountOnExit>
        <CardContent sx={{ pt: 0 }}>
          <List sx={{ pt: 0 }}>
            {sessions.map((session, index) => (
              <div key={index}>
                <SessionListItem session={session} />
              </div>
            ))}
          </List>
        </CardContent>
      </Collapse>
    </>
  );
}
