import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import Stack from '@mui/material/Stack';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Slider from '@mui/material/Slider';
import { PAYMENT_TYPE, SESSION_TYPE, paymentTypeMapping, sportTypeMapping } from '../../_helpers/journey.constants';
import { Dialog, DialogTitle, DialogContent, DialogActions, IconButton } from '@mui/material';
import { useSessionData } from '../../_hooks/useSession.hook';
import { removeSession, updateSession } from '../../_services/session.service';

const DEFAULT_DURATION = 15;

interface IEditSessionProps {
  id: string;
  open: boolean;
  onClose: () => void;
}

export default function EditSessionDialog(props: IEditSessionProps) {
  const { id, open, onClose } = props;
  const { data: session } = useSessionData(id);
  const [sessionType, setSessionType] = React.useState<{ name: string; enum: SESSION_TYPE }>(sportTypeMapping[0]);
  const [paymentType, setPaymentType] = React.useState<{ name: string; enum: PAYMENT_TYPE }>(paymentTypeMapping[0]);
  const [saveLoading, setSaveLoading] = React.useState(false);
  const [deleteLoading, setDeleteLoading] = React.useState(false);
  const [amount, setAmount] = React.useState<number>(DEFAULT_DURATION);
  const [name, setName] = React.useState<string | undefined>('');

  React.useEffect(() => {
    if (session) {
      setName(`${session.guest.surname} ${session.guest.lastname}`);
      setAmount(session.amount);
      const sType = sportTypeMapping.find((type) => type.enum === session.sessionType);
      if (sType) setSessionType(sType);
      const pType = paymentTypeMapping.find((type) => type.enum === session.paymentType);
      if (pType) setPaymentType(pType);
    }
  }, [session]);

  const handleSave = () => {
    if (!session) return;
    setSaveLoading(true);
    updateSession({ id, sessionType: sessionType.enum, paymentType: paymentType.enum, amount }).then(() => {
      setTimeout(() => {
        setSaveLoading(false);
        onClose();
      }, 400);
    });
  };

  const handleDelete = () => {
    if (!session) return;
    setDeleteLoading(true);
    removeSession(id).then(() => {
      setTimeout(() => {
        setDeleteLoading(false);
        onClose();
      }, 400);
    });
  };

  const handleSportTypeChanged = (type: SESSION_TYPE) => {
    const obj = sportTypeMapping.find((entry) => entry.enum === type);
    if (obj) setSessionType(obj);
  };

  const handlePaymentTypeChanged = (type: PAYMENT_TYPE) => {
    const obj = paymentTypeMapping.find((entry) => entry.enum === type);
    if (obj) setPaymentType(obj);
  };

  const handleSliderChange = (newValue: number | number[]) => {
    setAmount(newValue as number);
  };

  return (
    <Dialog onClose={onClose} open={open}>
      <DialogTitle>
        <>
          Session bearbeiten
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </>
      </DialogTitle>
      <DialogContent sx={{ padding: 0.5 }}>
        <Stack direction="column" spacing={2} ml={2} mr={2} mb={2} minWidth={230} marginTop={1}>
          <FormControl fullWidth>
            <TextField id="outlined-helperText" label="Driver" value={name} disabled />
          </FormControl>
          <FormControl fullWidth>
            <InputLabel id="sport-type-select-label">Sportart</InputLabel>
            <Select
              labelId="sport-type-select-label"
              id="sport-type-select"
              label="Sportart"
              onChange={(e) => handleSportTypeChanged(e.target.value as SESSION_TYPE)}
              value={sessionType.enum}
            >
              {sportTypeMapping.map((sportType, index) => (
                <MenuItem key={index} value={sportType.enum}>
                  {sportType.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel id="payment-type-select-label">Verrechnungsart</InputLabel>
            <Select
              labelId="payment-type-select-label"
              id="payment-type-select"
              label="Verrechnungsart"
              disabled={session?.guest.isMember}
              onChange={(e) => handlePaymentTypeChanged(e.target.value as PAYMENT_TYPE)}
              value={paymentType.enum}
            >
              {paymentTypeMapping.map((type, index) => (
                <MenuItem key={index} value={type.enum}>
                  {type.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Box sx={{ mb: 1, mt: 1, display: 'flex', alignItems: 'center' }}>
            <Slider
              sx={{ mr: 2 }}
              value={amount}
              step={session?.guest?.isMember ? 1 : 15} // Change to 15min step if the guest is not a member
              max={60}
              valueLabelDisplay="auto"
              onChange={(event: Event, newValue: number | number[]) => handleSliderChange(newValue)}
            />
            <TextField
              sx={{ width: '140px' }}
              id="duration-text-field"
              value={amount}
              label="Dauer"
              InputProps={{
                readOnly: true,
                endAdornment: <InputAdornment position="start">min</InputAdornment>,
              }}
            />
          </Box>
        </Stack>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          onClick={handleDelete}
          sx={{ flex: 1 }}
          startIcon={<DeleteIcon />}
          variant="outlined"
          color="error"
          loading={deleteLoading}
          loadingPosition="start"
        >
          Delete
        </LoadingButton>
        <LoadingButton
          disabled={amount > 0 ? false : true}
          onClick={handleSave}
          loading={saveLoading}
          loadingPosition="start"
          startIcon={<SaveIcon />}
          variant="contained"
          sx={{ flex: 1 }}
        >
          Save
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
