import { useParams } from 'react-router-dom';
import GuestsOfJourney from '../components/Guests/GuestsOfJourney';
import MenuBar from '../components/AppBar/MenuBar';
import SessionsOfJourney from '../components/Sessions/SessionsOfJourney';
import { useJourneyData } from '../_hooks/useJourney.hook';
import { Backdrop, CircularProgress } from '@mui/material';
import BoatOfJourney from '../components/Boat/BoatOfJourney';

export default function JourneyDetail() {
  const { id } = useParams();
  const { isLoading, data: journey } = useJourneyData(id ?? 'unknown');

  return (
    <>
      <MenuBar />
      {journey ? (
        <>
          <BoatOfJourney journey={journey} />
          <SessionsOfJourney journeyId={journey.id} />
          <GuestsOfJourney journeyId={journey.id} />
        </>
      ) : null}
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}
