import * as React from 'react';
import List from '@mui/material/List';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import SaveIcon from '@mui/icons-material/Save';
import AddIcon from '@mui/icons-material/Add';
import GuestsListItem from './GuestListItem';
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Fab,
  Grid,
  TextField,
} from '@mui/material';
import { assignGuest, removeGuestAssignment } from '../../_services/journey.service';
import { IGuest } from '../../_interfaces/guest.interface';
import { getGuestsAndMembers } from '../../_services/member.service';
import { guestHasSessions } from '../../_services/guest.service';
import { LoadingButton } from '@mui/lab';
import { useGuestsOfJourneyData, useSessionsOfJourneyData } from '../../_hooks/useJourney.hook';

interface IGuestsOfJourney {
  readonly journeyId: string;
}

interface IAutocompleteGuest {
  readonly label: string;
  readonly id: string;
  readonly isMember: boolean;
}

export default function GuestsOfJourney(props: IGuestsOfJourney) {
  const { journeyId } = props;
  const [registeredGuests, setRegisteredGuests] = React.useState<IGuest[]>([]); // Available guests not assigned to journey
  const [selectedGuest, setSelectedGuest] = React.useState<IAutocompleteGuest | null>(null);
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const { data: guests, refetch: refetchGuests } = useGuestsOfJourneyData(journeyId);
  const { data: sessions } = useSessionsOfJourneyData(journeyId);

  React.useEffect(() => {
    reloadRegisteredGuests();
  }, [guests]);

  const reloadRegisteredGuests = () => {
    const guestAndMembers: IGuest[] = [];
    getGuestsAndMembers().then((res) => {
      if (guests) {
        res.forEach((entry) => {
          if (!guests.find((guest) => guest.id === entry.id)) guestAndMembers.push(entry);
        });
      }
      setRegisteredGuests(guestAndMembers);
    });
  };

  const mapGuests = (guests: IGuest[]): IAutocompleteGuest[] => {
    return guests.map((guest) => {
      return {
        label: `${guest.surname} ${guest.lastname}`,
        id: guest.id,
        isMember: guest.isMember,
      };
    });
  };

  const handleAddGuest = () => {
    if (selectedGuest) {
      setLoading(true);
      assignGuest(journeyId, selectedGuest.id).then(() => {
        refetchGuests();
        setSelectedGuest(null);
        setLoading(false);
      });
    }
  };

  const handleRemoveGuestByIndex = (guestId: string) => {
    removeGuestAssignment(journeyId, guestId).then(() => refetchGuests());
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <Card sx={{ margin: 1 }}>
        <CardHeader
          action={
            <Fab onClick={handleOpen} color="primary" size="medium" aria-label="add">
              <AddIcon />
            </Fab>
          }
          title="Gäste"
        />
        <CardContent>
          <List sx={{ pt: 0 }} dense={true}>
            <Divider sx={{ marginBottom: 1 }} />
            {guests?.map((guest) => (
              <div key={guest.id}>
                <GuestsListItem
                  guest={guest}
                  secondaryAction={
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      disabled={guestHasSessions(guest, sessions ?? [])}
                      onClick={() => handleRemoveGuestByIndex(guest.id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  }
                />
                <Divider sx={{ marginTop: 0.5, marginBottom: 0.5 }} />
              </div>
            ))}
          </List>
        </CardContent>
      </Card>
      {/* Select Guest Dialog */}
      <Dialog onClose={handleClose} open={open}>
        <DialogTitle>Gast auswählen</DialogTitle>
        <DialogContent>
          <Autocomplete
            id="combo-box-guests"
            value={selectedGuest}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            onChange={(event: any, newValue: IAutocompleteGuest | null) => {
              setSelectedGuest(newValue);
            }}
            options={mapGuests(registeredGuests)}
            sx={{ margin: 1, minWidth: 220 }}
            renderInput={(params) => <TextField {...params} label="Gast suchen" />}
            renderOption={(props, option) => (
              <Box component="li" {...props}>
                <Grid container justifyContent="space-between" alignItems="center">
                  {option.label}
                  <Chip
                    label={option.isMember ? 'M' : 'G'}
                    color={option.isMember ? 'primary' : 'default'}
                    size="small"
                    variant="outlined"
                  />
                </Grid>
              </Box>
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" sx={{ flex: 1 }}>
            Close
          </Button>
          <LoadingButton
            onClick={handleAddGuest}
            color="primary"
            disabled={!selectedGuest}
            variant="contained"
            sx={{ flex: 1 }}
            loading={loading}
            loadingPosition="start"
            startIcon={<SaveIcon />}
          >
            Add
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
