import React from 'react';
import { QueryClientProvider, QueryClient } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import SignIn from './views/SignIn';
import { Routes, Route, Navigate } from 'react-router-dom';
import { PrivateRoute } from './routes/PrivateRoute';
import { PublicRoute } from './routes/PublicRoute';
import Home from './views/Home';
import { RecoilRoot } from 'recoil';
import JourneyDetail from './views/JourneyDetail';
import PageNotFound from './views/PageNotFound';

const queryClient = new QueryClient();

function App() {
  return (
    <div className="App">
      <QueryClientProvider client={queryClient}>
        <RecoilRoot>
          <Routes>
            <Route path="/home" element={<PrivateRoute />}>
              <Route path="/home" element={<Home />} />
            </Route>
            <Route path="/journey-detail" element={<PrivateRoute />}>
              <Route path="/journey-detail/:id" element={<JourneyDetail />} />
            </Route>
            <Route path="/login" element={<PublicRoute />}>
              <Route path="/login" element={<SignIn />} />
            </Route>
            <Route path="/" element={<Navigate replace to="/home" />} />
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </RecoilRoot>
        <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
      </QueryClientProvider>
    </div>
  );
}

export default App;
