import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { getUser, logout } from '../../_services';
import { useLocation, useNavigate } from 'react-router-dom';

export default function MenuBar() {
  const navigate = useNavigate();
  const location = useLocation();
  const user = getUser();

  const goToHome = () => {
    navigate('/');
  };
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            {user?.surname}
          </Typography>
          {location.pathname !== '/home' ?  <Button color="inherit" onClick={goToHome}>
            Home
          </Button> : null}
          <Button color="inherit" onClick={logout}>
            Logout
          </Button>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
