import { Avatar, CardHeader, Chip, Grid, Typography } from "@mui/material";
import { IJourney } from "../../_interfaces/journey.interface";
import { format } from "date-fns";
import { DATE_FORMAT } from "../../_helpers/app.constants";
import { red } from "@mui/material/colors";

interface IBoatHeaderProps {
  journey: IJourney;
  action?: any;
}

export default function BoatOfJourney(props: IBoatHeaderProps) {
  const { journey, action } = props;
  return (
    <CardHeader
      avatar={
        <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
          {journey.boat?.number || 0}
        </Avatar>
      }
      action={action}
      title={
        <Grid container alignItems={"center"} paddingRight={3}>
          <Grid item xs={7}>
            <Typography variant="h6">{journey.boat.brand}</Typography>
          </Grid>
          <Grid item xs={5} sx={{ textAlign: "right" }}>
            <Chip label={journey.state} size="small" color="primary" variant="outlined" />
          </Grid>
        </Grid>
      }
      subheader={
        <Grid container paddingRight={3}>
          <Grid item xs={4}>
            Startzeit
          </Grid>
          <Grid item xs={8}>
            <Typography variant="body2" sx={{ fontWeight: "bold", textAlign: "right" }}>
              {format(new Date(journey.startDate), DATE_FORMAT)}
            </Typography>
          </Grid>
          <Grid item xs={9}>
            Betriebsstunden Start
          </Grid>
          <Grid item xs={3}>
            <Typography variant="body2" sx={{ fontWeight: "bold", textAlign: "right" }}>
              {journey.operatingHoursStart}
            </Typography>
          </Grid>
        </Grid>
      }
    />
  );
}
