import { ISession } from "../../_interfaces/session.interface";
import { getFriendlyNameByPaymentType, getFriendlyNameBySportType } from "../../_services/utils.service";
import { ListItem, Grid, ListItemText, Chip } from "@mui/material";

interface IGuestListItemProps {
  session: ISession;
  secondaryAction?: any;
}

export default function SessionListItem(props: IGuestListItemProps) {
  const { session, secondaryAction } = props;
  const { guest } = session;

  return (
    <ListItem secondaryAction={secondaryAction}>
      <Grid container justifyContent="space-between" alignItems="center" marginRight={1}>
        <ListItemText
          // primary={
          //   <Grid container justifyContent="space-between" alignItems="center" marginRight={1}>
          //     {guest.surname} {guest.lastname}
          //     <Chip label={`${session.amount} min`} size="small" color="primary" variant="outlined" />
          //   </Grid>
          // }
          primary={`${guest.surname} ${guest.lastname}`}
          secondary={`${getFriendlyNameBySportType(session.sessionType)} - ${getFriendlyNameByPaymentType(
            session.paymentType
          )}`}
        />
        <Chip label={`${session.amount} min`} size="small" color="primary" variant="outlined" />
      </Grid>
    </ListItem>
  );
}
