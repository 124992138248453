import React from 'react';
import { Card, IconButton } from '@mui/material';
import { IJourney } from '../../_interfaces/journey.interface';
import EditIcon from '@mui/icons-material/Edit';
import BoatHeader from './BoatHeader';
import CompleteJourneyDialog from '../Journey/CompleteJourneyDialog';

interface IBoatOfJourneyProps {
  journey: IJourney;
}

export default function BoatOfJourney(props: IBoatOfJourneyProps) {
  const { journey } = props;
  const [open, setOpen] = React.useState(false);
  return (
    <>
      <Card sx={{ margin: 1 }}>
        <BoatHeader
          journey={journey}
          action={
            <IconButton aria-label="settings" onClick={() => setOpen(true)}>
              <EditIcon />
            </IconButton>
          }
        />
      </Card>
      <CompleteJourneyDialog journeyId={journey.id} open={open} onClose={() => setOpen(false)} />
    </>
  );
}
