import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import Stack from '@mui/material/Stack';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { IGuest } from '../../_interfaces/guest.interface';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Slider from '@mui/material/Slider';
import ListItemText from '@mui/material/ListItemText';
import { PAYMENT_TYPE, SESSION_TYPE, sportTypeMapping } from '../../_helpers/journey.constants';
import { Dialog, DialogTitle, DialogContent, DialogActions, Chip, Grid } from '@mui/material';
import { createSession } from '../../_services/session.service';
import { useGuestsOfJourneyData, useSessionsOfJourneyData } from '../../_hooks/useJourney.hook';

const DEFAULT_DURATION = 15;

interface ICreateSessionProps {
  journeyId: string;
  open: boolean;
  onClose: () => void;
}

export default function CreateSessionDialog(props: ICreateSessionProps) {
  const { journeyId, open, onClose } = props;
  const [sportType, setSportType] = React.useState<{ name: string; enum: SESSION_TYPE }>(sportTypeMapping[0]);
  const [guest, setGuest] = React.useState<IGuest | null>(null);
  const [loading, setLoading] = React.useState(false);
  const [amount, setAmount] = React.useState<number>(DEFAULT_DURATION);
  const { data: guests } = useGuestsOfJourneyData(journeyId);
  const { refetch: refetchSessions } = useSessionsOfJourneyData(journeyId);

  function handleSave() {
    if (!guest) return;
    setLoading(true);
    createSession({
      journeyId,
      guestId: guest.id,
      sessionType: sportType.enum,
      amount,
      paymentType: guest.isMember ? PAYMENT_TYPE.MEMBER_INVOICE : PAYMENT_TYPE.TWINT,
    }).then(() => {
      setGuest(null);
      setAmount(DEFAULT_DURATION);
      setLoading(false);
      refetchSessions();
    });
  }

  const handleSportTypeChanged = (sportType: SESSION_TYPE) => {
    const obj = sportTypeMapping.find((entry) => entry.enum === sportType);
    if (obj) setSportType(obj);
  };

  const handleGuestChanged = (guestName: string) => {
    if (guestName && guestName.includes('_')) {
      const arr = guestName.split('_');
      const obj = guests?.find((entry) => entry.surname === arr[0] && entry.lastname === arr[1]);
      if (obj) setGuest(obj);
    }
  };

  const handleSliderChange = (newValue: number | number[]) => {
    setAmount(newValue as number);
  };

  return (
    <Dialog onClose={onClose} open={open}>
      <DialogTitle>Session anlegen</DialogTitle>
      <DialogContent sx={{ padding: 0.5 }}>
        <Stack direction="column" spacing={2} ml={2} mr={2} mb={2} minWidth={230} marginTop={1}>
          <FormControl fullWidth>
            <InputLabel id="guest-select-label">Rider</InputLabel>
            <Select
              labelId="guest-select-label"
              id="guest-select"
              label="Rider"
              onChange={(e) => handleGuestChanged(e.target.value)}
              value={guest ? `${guest.surname}_${guest.lastname}` : ''}
            >
              {guests?.map((guest, index) => (
                <MenuItem key={index} value={`${guest.surname}_${guest.lastname}`}>
                  <Grid container alignItems="center">
                    <ListItemText primary={`${guest.surname} ${guest.lastname}`} sx={{ ml: 1.5 }} />
                    <Chip
                      label={guest.isMember ? 'Mitglied' : 'Gast'}
                      color={guest.isMember ? 'primary' : 'default'}
                      size="small"
                      variant="outlined"
                    />
                  </Grid>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel id="sport-type-select-label">Sportart</InputLabel>
            <Select
              labelId="sport-type-select-label"
              id="sport-type-select"
              label="Sportart"
              onChange={(e) => handleSportTypeChanged(e.target.value as SESSION_TYPE)}
              value={sportType.enum}
            >
              {sportTypeMapping.map((sportType, index) => (
                <MenuItem key={index} value={sportType.enum}>
                  {sportType.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Box sx={{ mb: 1, mt: 1, display: 'flex', alignItems: 'center' }}>
            <Slider
              sx={{ mr: 2 }}
              value={amount}
              step={guest?.isMember ? 1 : 15} // Change to 15min step if the guest is not a member
              max={60}
              valueLabelDisplay="auto"
              onChange={(event: Event, newValue: number | number[]) => handleSliderChange(newValue)}
            />
            <TextField
              sx={{ width: '140px' }}
              id="duration-text-field"
              value={amount}
              label="Dauer"
              InputProps={{
                readOnly: true,
                endAdornment: <InputAdornment position="start">min</InputAdornment>,
              }}
            />
          </Box>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} sx={{ flex: 1 }}>
          Close
        </Button>
        <LoadingButton
          disabled={guest && amount > 0 ? false : true}
          onClick={handleSave}
          loading={loading}
          loadingPosition="start"
          startIcon={<SaveIcon />}
          variant="contained"
          sx={{ flex: 1 }}
        >
          Add
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
