import axios, { Axios, AxiosError } from 'axios';
import { ACCESS_TOKEN, USER } from './app.constants';
import { LOGIN_URL, logout } from '../_services';

export function setupAxios(axios: any) {
  axios.defaults.headers.Accept = 'application/json';
  axios.defaults.baseURL = '/api';
  axios.interceptors.request.use(
    (config: { headers: { Authorization: string } }) => {
      const token = getToken();
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }

      return config;
    },
    (err: any) => {
      Promise.reject(err);
    }
  );
  axios.interceptors.response.use(
    (res: any) => res,
    (error: AxiosError) => {
      const { data, status, config } = error.response!;
      switch (status) {
        case 401:
          // Ignore logout for Sign In Page
          if (config.url !== LOGIN_URL) {
            console.error('unauthorized');
            logout();
          }
          break;

        // case 404:
        //   console.error('/not-found');
        //   break;

        case 500:
          console.error('Internal Server Error');
          break;
      }
      return Promise.reject(error);
    }
  );
}

const getToken = (): string | null => {
  const user = localStorage.getItem(USER);
  if (user) return JSON.parse(user)[ACCESS_TOKEN];
  return null;
};

export const AxiosInstance: Axios = axios;
setupAxios(AxiosInstance);

export enum REQUEST_STATE {
  IDLE,
  LOADING,
  SUCCESS,
  FAILURE,
}
