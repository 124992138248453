import { Box, Button, Typography } from '@mui/material';
import MenuBar from '../components/AppBar/MenuBar';
import { useNavigate } from 'react-router-dom';

export default function PageNotFound() {
  const navigate = useNavigate();

  const goToHome = () => {
    navigate('/');
  };
  return (
    <>
      <MenuBar />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          height: '100%',
          minHeight: '100vh',
        }}
      >
        <Typography variant="h6" sx={{ margin: 2, justifyContent: 'center' }}>
          The page you’re looking for doesn’t exist.
        </Typography>
        <Button variant="contained" onClick={goToHome}>
          Back Home
        </Button>
      </Box>
    </>
  );
}
