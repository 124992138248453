export enum JOURNEY_STATE {
  PLANNED = 'PLANNED',
  ONGOING = 'ONGOING',
  COMPLETED = 'COMPLETED',
  APPROVED = 'APPROVED',
}

export enum SESSION_TYPE {
  WAKE_SURF = 'WAKE_SURF',
  WAKE_BOARD = 'WAKE_BOARD',
  MONO_SKI = 'MONO_SKI',
  BARE_FOOT = 'BARE_FOOT',
  WATER_SKI = 'WATER_SKI',
  SKY_SKI = 'SKY_SKI',
  FREE_DRIVE = 'FREE_DRIVE',
}

export enum PAYMENT_TYPE {
  MEMBER_INVOICE = 'MEMBER_INVOICE',
  CASH = 'CASH',
  TWINT = 'TWINT',
}

export const sportTypeMapping = [
  { name: 'Wakesurf', enum: SESSION_TYPE.WAKE_SURF },
  { name: 'Wakeboard', enum: SESSION_TYPE.WAKE_BOARD },
  { name: 'Monoski', enum: SESSION_TYPE.MONO_SKI },
  { name: 'Skyski', enum: SESSION_TYPE.SKY_SKI },
  { name: 'Barefoot', enum: SESSION_TYPE.BARE_FOOT },
  { name: 'Waterski', enum: SESSION_TYPE.WATER_SKI },
  { name: 'Freifahrt', enum: SESSION_TYPE.FREE_DRIVE },
];

export const paymentTypeMapping = [
  { name: 'Rechnung', enum: PAYMENT_TYPE.MEMBER_INVOICE },
  { name: 'Bar', enum: PAYMENT_TYPE.CASH },
  { name: 'Twint', enum: PAYMENT_TYPE.TWINT },
];
