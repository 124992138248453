import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { IUser } from '../../_interfaces/user.interface';
import { Role } from '../../_helpers/role.constants';
import { getBoatDrivers } from '../../_services/member.service';

interface IBoatSelectProps {
  onBoatDriverChanged: (value: IUser) => void;
}

export default function BoatDriverSelect(props: IBoatSelectProps) {
  const { onBoatDriverChanged } = props;
  const [selectedBoatDriver, setSelectedBoatDriver] = React.useState<IUser | null>(null);
  const [boatDrivers, setBoatDrivers] = React.useState<IUser[]>([
    
  ]);

  React.useEffect(() => {
    getBoatDrivers().then((res) => {
      setBoatDrivers(res);
    });
  }, []);

  const handleChange = (event: SelectChangeEvent) => {
    const boatDriver = boatDrivers.find((boatDriver) => boatDriver.id === event.target.value);
    if (boatDriver) {
      setSelectedBoatDriver(boatDriver);
      onBoatDriverChanged(boatDriver);
    }
  };

  return (
    <FormControl fullWidth>
      <InputLabel id="demo-simple-select-label">Fahrer Auswahl</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        label="Fahrer Auswahl"
        onChange={handleChange}
        value={selectedBoatDriver?.id ? selectedBoatDriver.id : ''}
      >
        {boatDrivers.map((boatDriver) => (
          <MenuItem
            key={boatDriver.id}
            value={boatDriver.id}
          >{`${boatDriver.surname} ${boatDriver.lastname}`}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
