import * as React from "react";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import PersonIcon from "@mui/icons-material/Person";
import Avatar from "@mui/material/Avatar";
import { orange, blue } from "@mui/material/colors";
import { IGuest } from "../../_interfaces/guest.interface";
import { Button, Chip, Grid } from "@mui/material";

interface IGuestListItemProps {
  guest: IGuest;
  secondaryAction?: any;
}

export default function GuestsListItem(props: IGuestListItemProps) {
  const { guest, secondaryAction } = props;

  return (
    <ListItem secondaryAction={secondaryAction}>
      <Grid container justifyContent="space-between" alignItems="center" marginRight={1}>
        <ListItemText
          primary={`${guest.surname} ${guest.lastname}`}
          // secondary={
          //   <Chip
          //     label={guest.isMember ? "Mitglied" : "Gast"}
          //     color={guest.isMember ? "primary" : "default"}
          //     size="small"
          //     variant="outlined"
          //   />
          // }
        />
        <Chip
          label={guest.isMember ? "Mitglied" : "Gast"}
          color={guest.isMember ? "primary" : "default"}
          size="small"
          variant="outlined"
        />
      </Grid>
    </ListItem>
  );
}
