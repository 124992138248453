import { ICreateJourney } from '../_interfaces/create-journey.interface';
import { IGuest } from '../_interfaces/guest.interface';
import { IJourney } from '../_interfaces/journey.interface';
import { ISession } from '../_interfaces/session.interface';
import { IUpdateJourney } from '../_interfaces/update-journey.interface';
import { axiosClient } from '../_utils/axios-utils';

export const JOURNEY_URL = '/journey';

export const createJourney = (journey: ICreateJourney): Promise<void> => {
  return axiosClient
    .post(JOURNEY_URL, journey)
    .then(() => {
      return;
    })
    .catch((err) => {
      const { message } = err.response.data;
      throw new Error(message);
    });
};

export const updateJourney = (journey: IUpdateJourney): Promise<void> => {
  const { id, state, operatingHoursEnd, endDate } = journey;
  return axiosClient
    .put(`${JOURNEY_URL}/${id}`, { state, operatingHoursEnd, endDate })
    .then(() => {
      return;
    })
    .catch((err) => {
      const { message } = err.response.data;
      throw new Error(message);
    });
};

export const removeJourney = (id: string): Promise<void> => {
  return axiosClient
    .delete(`${JOURNEY_URL}/${id}`)
    .then(() => {
      return;
    })
    .catch((err) => {
      const { message } = err.response.data;
      throw new Error(message);
    });
};

export const getJournies = (): Promise<IJourney[]> => {
  return axiosClient.get(JOURNEY_URL);
};

export const getJourneyById = (id: string): Promise<IJourney> => {
  return axiosClient.get(`${JOURNEY_URL}/${id}`);
};

export const getOngoingJourneyByDriverId = (driverId: string): Promise<any> => {
  return axiosClient.get(`${JOURNEY_URL}/driver/${driverId}`);
};

export const getGuestsOfJourneyById = (id: string): Promise<IGuest[]> => {
  return axiosClient.get(`${JOURNEY_URL}/${id}/assigned_guest`);
};

export const assignGuest = (journeyId: string, guestId: string): Promise<void> => {
  return axiosClient
    .post(`${JOURNEY_URL}/${journeyId}/assign_guest/${guestId}`)
    .then(() => {
      return;
    })
    .catch((err) => {
      const { message } = err.response.data;
      throw new Error(message);
    });
};

export const removeGuestAssignment = (journeyId: string, guestId: string): Promise<void> => {
  return axiosClient
    .delete(`${JOURNEY_URL}/${journeyId}/assign_guest/${guestId}`)
    .then(() => {
      return;
    })
    .catch((err) => {
      const { message } = err.response.data;
      throw new Error(message);
    });
};

export const getSessionsOfJourneyById = (id: string): Promise<ISession[]> => {
  return axiosClient.get(`${JOURNEY_URL}/${id}/assigned_session`);
};
