import { PAYMENT_TYPE, SESSION_TYPE, paymentTypeMapping } from '../_helpers/journey.constants';
import { sportTypeMapping } from './../_helpers/journey.constants';
export const getFriendlyNameBySportType = (sportType: SESSION_TYPE): string => {
  const obj = sportTypeMapping.find((entry) => entry.enum === sportType);
  if (obj) return obj.name;
  return 'UNKNOWN_SPORT_TYPE';
};

export const getFriendlyNameByPaymentType = (paymentType: PAYMENT_TYPE): string => {
  const obj = paymentTypeMapping.find((entry) => entry.enum === paymentType);
  if (obj) return obj.name;
  return 'UNKNOWN_PAYMENT_TYPE';
};
