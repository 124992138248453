import { IGuest } from '../_interfaces/guest.interface';
import { ISession } from '../_interfaces/session.interface';
import { axiosClient } from '../_utils/axios-utils';

export const GUEST_URL = '/guest';

export const getGuests = (): Promise<IGuest[]> => {
  return axiosClient.get(`${GUEST_URL}`);
};

export const guestHasSessions = (guest: IGuest, sessions: ISession[]): boolean => {
  const guestId = guest.id;
  if (sessions.some((session) => session.guest.id === guestId)) return true;
  return false;
};
