import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { IBoat } from '../../_interfaces/boat.interface';
import { getBoats } from '../../_services/boat.service';
import { PLACEHOLDER_BOAT } from '../../_helpers/app.constants';

interface IBoatSelectProps {
  onBootChanged: (value: IBoat) => void;
}

export default function BoatSelect(props: IBoatSelectProps) {
  const { onBootChanged } = props;
  const [selectedBoat, setSelectedBoat] = React.useState<IBoat | null>(null);
  const [boats, setBoats] = React.useState<IBoat[]>([]);

  React.useEffect(() => {
    getBoats().then((res) => {
      setBoats(res.filter((boat) => boat.number !== PLACEHOLDER_BOAT));
    });
  }, []);

  const handleChange = (event: SelectChangeEvent) => {
    const boat = boats.find((boat) => boat.number === Number(event.target.value));
    if (boat) {
      setSelectedBoat(boat);
      onBootChanged(boat);
    }
  };

  return (
    <FormControl fullWidth>
      <InputLabel id="demo-simple-select-label">Boot Auswahl</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        label="Boot Auswahl"
        onChange={handleChange}
        value={selectedBoat?.number ? selectedBoat.number.toString() : ''}
      >
        {boats.map((boat) => (
          <MenuItem key={boat.number} value={boat.number}>{`Boot ${boat.number} - ${boat.brand}`}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
