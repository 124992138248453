import axios, { AxiosError, AxiosInstance, InternalAxiosRequestConfig, AxiosResponse } from 'axios';
import { ACCESS_TOKEN, USER } from '../_helpers/app.constants';
import { LOGIN_URL, logout } from '../_services/authentication.service';

const onRequest = (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
};

const onResponse = (response: AxiosResponse): AxiosResponse => {
  return response.data;
};

const onErrorResponse = (error: AxiosError | Error): Promise<AxiosError> => {
  if (axios.isAxiosError(error)) {
    // const { message } = error;
    const { statusText, status, config } = (error.response as AxiosResponse) ?? {};

    console.error(statusText);
    switch (status) {
      case 401:
        // Ignore logout for Sign In Page
        if (config.url !== LOGIN_URL) {
          logout();
        }
        break;

      case 500:
        console.error('Internal Server Error');
        break;
    }
  }
  return Promise.reject(error);
};

const getToken = (): string | null => {
  const user = localStorage.getItem(USER);
  if (user) return JSON.parse(user)[ACCESS_TOKEN];
  return null;
};

const setupInterceptors = (instance: AxiosInstance): AxiosInstance => {
  instance.interceptors.request.use(onRequest, onErrorResponse);
  instance.interceptors.response.use(onResponse, onErrorResponse);
  return instance;
};

export const axiosClient = setupInterceptors(
  axios.create({ baseURL: '/api', headers: { Accept: 'application/json' } })
);
