import React from "react";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import { DialogContent, DialogActions, TextField, Stack, Grid, Divider, Typography, Chip } from "@mui/material";
import { LoadingButton, LocalizationProvider, MobileDateTimePicker } from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";
import { useGuestsOfJourneyData, useJourneyData, useSessionsOfJourneyData } from "../../_hooks/useJourney.hook";
import IconButton from "@mui/material/IconButton";
import SessionListCollapse from "../Sessions/SessionListCollapse";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { DATE_FORMAT } from "../../_helpers/app.constants";
import { removeJourney, updateJourney } from "../../_services/journey.service";
import { JOURNEY_STATE } from "../../_helpers/journey.constants";
import { useNavigate } from "react-router-dom";
import BoatOperatingHours from "../Boat/BoatOperatingHours";

interface ICompleteJourneyDialogProps {
  journeyId: string;
  open: boolean;
  onClose: () => void;
}

export default function CompleteJourneyDialog(props: ICompleteJourneyDialogProps) {
  const { journeyId, open, onClose } = props;
  const navigate = useNavigate();
  const { data: journey, remove: removeJourneyData } = useJourneyData(journeyId);
  const { data: sessions, remove: removeSessionData } = useSessionsOfJourneyData(journeyId);
  const { remove: removeGuestData } = useGuestsOfJourneyData(journeyId);
  const [completeLoading, setCompleteLoading] = React.useState(false);
  const [deleteLoading, setDeleteLoading] = React.useState(false);
  const [endDate, setEndDate] = React.useState<Date | null>(new Date());
  const [operatingHoursEnd, setOperatingHoursEnd] = React.useState<number>(0);

  React.useEffect(() => {
    if (open) {
      setEndDate(new Date());
    }
  }, [open]);

  React.useEffect(() => {
    if (journey) {
      setOperatingHoursEnd(journey.operatingHoursStart);
    }
  }, [journey]);

  const clearQueryStates = () => {
    removeSessionData();
    removeGuestData();
    removeJourneyData();
  };

  const handleCompleteSession = () => {
    if (!endDate) return;
    setCompleteLoading(true);
    updateJourney({ id: journeyId, state: JOURNEY_STATE.COMPLETED, endDate, operatingHoursEnd }).then(() => {
      setTimeout(() => {
        setCompleteLoading(false);
        navigate(`/`);
        clearQueryStates();
      }, 400);
    });
  };

  const handleDelete = () => {
    setDeleteLoading(true);
    removeJourney(journeyId).finally(() => {
      clearQueryStates();
      setTimeout(() => {
        setDeleteLoading(false);
        navigate(`/`);
      }, 400);
    });
  };

  return (
    <Dialog onClose={onClose} open={open} fullWidth>
      <DialogTitle>
        <>
          Fahrt abschliessen
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </>
      </DialogTitle>
      <DialogContent sx={{ padding: 1 }}>
        <Stack spacing={1} direction="column">
          <Divider textAlign="left">
            <Typography variant="caption">Betriebsstunden Ende</Typography>
          </Divider>
          <Grid container sx={{ flexDirection: "column", alignItems: "center" }}>
            {/* <Grid item sx={{ marginBottom: 2 }}>
              <BoatOperatingHours initialValue={operatingHoursEnd} onHoursChanged={setOperatingHoursEnd} />
            </Grid>
            <Grid item sx={{ marginBottom: 2 }}>
              <Chip label={`${operatingHoursEnd} h`} />
            </Grid> */}
            <Grid item sx={{ marginBottom: 2 }}>
              <BoatOperatingHours initialValue={operatingHoursEnd} onHoursChanged={setOperatingHoursEnd} />
            </Grid>
          </Grid>

          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <MobileDateTimePicker
              value={endDate}
              label="End Zeit und Datum"
              inputFormat={DATE_FORMAT}
              ampm={false}
              onChange={(newValue) => {
                setEndDate(newValue);
              }}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </Stack>
        {sessions ? <SessionListCollapse sessions={sessions} /> : null}
      </DialogContent>
      <DialogActions>
        <LoadingButton
          onClick={handleDelete}
          sx={{ flex: 1 }}
          startIcon={<DeleteIcon />}
          variant="outlined"
          color="error"
          loading={deleteLoading}
          loadingPosition="start"
        >
          Delete
        </LoadingButton>
        <LoadingButton
          onClick={handleCompleteSession}
          color="primary"
          // disabled={!selectedGuest}
          variant="contained"
          sx={{ flex: 1 }}
          loading={completeLoading}
          loadingPosition="start"
          startIcon={<SaveIcon />}
        >
          Complete
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
