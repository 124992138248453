import * as React from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Box from '@mui/material/Box';
import { Chip, Grid, TextField, styled } from '@mui/material';
import { blueGrey, blue } from '@mui/material/colors';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

const StyledInput = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderRadius: 0,
      borderColor: blueGrey[200],
    },
    '&:hover fieldset': {
      borderColor: blueGrey[300],
    },
    '&.Mui-focused fieldset': {
      borderColor: blueGrey[500],
    },
    '& input': {
      textAlign: 'center',

      width: 60,

      color: blue[700],
    },
    '& .Mui-disabled': {
      color: blue[500],
    },
  },
});

interface ICounterWidgetProps {
  initialValue: number;
  min: number;
  max: number;
  onChanged: (counter: number) => void;
}

function CounterWidget(props: ICounterWidgetProps) {
  const { initialValue, min, max, onChanged } = props;
  const [count, setCount] = React.useState<number>(initialValue);

  React.useEffect(() => {
    onChanged(count);
  }, [count]);

  return (
    <Box>
      <ButtonGroup orientation="vertical" variant="outlined">
        <Button key="one" onClick={() => setCount((prev) => prev + 1)} size="large" disabled={count >= max}>
          <AddIcon fontSize="small" />
        </Button>
        <StyledInput key="two" value={count} disabled size="small" />
        <Button key="three" size="large" onClick={() => setCount((prev) => prev - 1)} disabled={count <= min}>
          <RemoveIcon fontSize="small" />
        </Button>
      </ButtonGroup>
    </Box>
  );
}

const getHoursFromFloat = (hours: number): number => {
  try {
    return Number(String(hours).split('.')[0]);
  } catch (error) {
    return 0;
  }
};

const getMinsFromFloat = (hours: number): number => {
  try {
    const str = String(hours).split('.');
    if (str.length === 2) return Number(str[1]);
    return 0;
  } catch (error) {
    return 0;
  }
};

interface IBoatOperatingHoursProps {
  initialValue: number;
  onHoursChanged: (value: number) => void;
}

export default function BoatOperatingHours(props: IBoatOperatingHoursProps) {
  const { initialValue, onHoursChanged } = props;
  const [hours, setHours] = React.useState<number>(getHoursFromFloat(initialValue));
  const [mins, setMins] = React.useState<number>(getMinsFromFloat(initialValue));

  React.useEffect(() => {
    onHoursChanged(Number(`${hours}.${mins}`));
  }, [hours, mins]);

  const handleHours = (res: number) => {
    setHours(res);
  };

  const handleMinutes = (res: number) => {
    setMins(res);
  };

  return (
    <Grid container spacing={1} sx={{ alignItems: 'center' }}>
      <Grid container spacing={1} sx={{ alignItems: 'center' }}>
        <Grid item>
          <CounterWidget initialValue={hours} min={0} max={9999} onChanged={handleHours} />
        </Grid>
        <Grid item>-</Grid>
        <Grid item>
          <CounterWidget initialValue={mins} min={0} max={9} onChanged={handleMinutes} />
        </Grid>
      </Grid>

      <Grid item>
        <Chip label={`${Number(`${hours}.${mins}`)} h`} />
      </Grid>
    </Grid>
  );
}

{
  /* <Grid item sx={{ marginBottom: 2 }}>
              <BoatOperatingHours initialValue={operatingHoursEnd} onHoursChanged={setOperatingHoursEnd} />
            </Grid>
            <Grid item sx={{ marginBottom: 2 }}>
              <Chip label={`${operatingHoursEnd} h`} />
            </Grid> */
}
