import { IGuest } from '../_interfaces/guest.interface';
import { IUser } from '../_interfaces/user.interface';
import { axiosClient } from '../_utils/axios-utils';
import { getGuests } from './guest.service';

export const MEMBER_URL = '/member';

export const getBoatDrivers = (): Promise<IUser[]> => {
  return axiosClient.get(`${MEMBER_URL}/drivers`);
};

export const getMembers = async (): Promise<IGuest[]> => {
  return axiosClient.get(`${MEMBER_URL}`);
};

export const getGuestsAndMembers = async (): Promise<IGuest[]> => {
  const guests = await getGuests();
  const members = await getMembers();
  return [...guests, ...members];
};
