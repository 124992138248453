import * as React from "react";
import Box from "@mui/material/Box";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import AddIcon from "@mui/icons-material/Add";
import Fab from "@mui/material/Fab";
import { IBoat } from "../../_interfaces/boat.interface";
import { DATE_FORMAT } from "../../_helpers/app.constants";
import { LoadingButton, LocalizationProvider, MobileDateTimePicker } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { Stack, TextField, InputAdornment, Alert, LinearProgress } from "@mui/material";
import BoatSelect from "../Boat/BoatSelect";
import SaveIcon from "@mui/icons-material/Save";
import { createJourney } from "../../_services/journey.service";
import { IUser } from "../../_interfaces/user.interface";
import BoatDriverSelect from "../Boat/BoatDriverSelect";
import { REQUEST_STATE } from "../../_helpers/request.helper";

interface ICreatJourneyProps {
  readonly ongoingJourney: boolean;
  onJourneyCreated: () => void;
}

export default function CreateJourney(props: ICreatJourneyProps) {
  const { ongoingJourney, onJourneyCreated } = props;
  const [open, setOpen] = React.useState(false);
  const [startDate, setStartDate] = React.useState<Date | null>(new Date());
  const [operatingHours, setOperatingHours] = React.useState<number>(0);
  const [selectedBoat, setSelectedBoat] = React.useState<IBoat | null>(null);
  const [selectedBoatDriver, setSelectedBoatDriver] = React.useState<IUser | null>(null);
  const [requestState, setRequestState] = React.useState<REQUEST_STATE>(REQUEST_STATE.IDLE);

  const handleSave = () => {
    if (!selectedBoat) throw new Error("No boat selected");
    if (!selectedBoatDriver) throw new Error("No boat driver selected");
    if (!startDate) throw new Error("No start date selected");

    createJourney({
      boatNumber: selectedBoat.number,
      driverId: selectedBoatDriver.id,
      operatingHoursStart: operatingHours,
      startDate: startDate ? startDate : new Date(),
    })
      .then(() => {
        setRequestState(REQUEST_STATE.SUCCESS);
        setTimeout(() => {
          setRequestState(REQUEST_STATE.IDLE);
          setOpen(false);
          onJourneyCreated();
        }, 1000);
      })
      .catch((err) => {
        console.error("Couldn't create Journey!", err);
        setRequestState(REQUEST_STATE.FAILURE);
      });
  };

  React.useEffect(() => {
    if (selectedBoat?.operatingHours) setOperatingHours(selectedBoat.operatingHours);
  }, [selectedBoat]);

  React.useEffect(() => {
    if (open) {
      if (requestState !== REQUEST_STATE.IDLE) setRequestState(REQUEST_STATE.IDLE);
      if (selectedBoat) setSelectedBoat(null);
      if (selectedBoatDriver) setSelectedBoatDriver(null);
    }
  }, [open]);

  const handleClose = () => setOpen(false);

  const showRequestState = () => {
    switch (requestState) {
      case REQUEST_STATE.IDLE:
        return null;
      case REQUEST_STATE.LOADING:
        return <LinearProgress />;
      case REQUEST_STATE.SUCCESS:
        return <Alert severity="success">Fahrt erfolgreich angelegt</Alert>;
      case REQUEST_STATE.FAILURE:
        return <Alert severity="error">Fahrt konnte nicht angelegt werden</Alert>;
      default:
        return null;
    }
  };

  return (
    <>
      {ongoingJourney ? null : (
        <>
          <Fab
            color="primary"
            aria-label="add"
            sx={{ position: "absolute", bottom: 20, right: 20 }}
            onClick={() => setOpen(true)}
            variant="extended"
          >
            <AddIcon />
            Fahrt anlegen
          </Fab>
          <Dialog onClose={handleClose} open={open}>
            <DialogTitle>Fahrt anlegen</DialogTitle>
            <Box sx={{ maxWidth: 400, minWidth: 250, paddingLeft: 1.5, paddingRight: 1.5, paddingBottom: 1.5 }}>
              <Stack direction="column" spacing={2} marginTop={2}>
                <BoatDriverSelect onBoatDriverChanged={setSelectedBoatDriver} />
                <BoatSelect onBootChanged={setSelectedBoat} />
                <TextField
                  label="Betriebsstunden Start"
                  value={operatingHours}
                  id="outlined-start-adornment"
                  onChange={(newValue) => {
                    setOperatingHours(Number(newValue.target.value));
                  }}
                  InputProps={{
                    endAdornment: <InputAdornment position="start">h</InputAdornment>,
                  }}
                />
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <MobileDateTimePicker
                    value={startDate}
                    label="Start Zeit und Datum"
                    inputFormat={DATE_FORMAT}
                    ampm={false}
                    onChange={(newValue) => {
                      setStartDate(newValue);
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
                {showRequestState()}
                <LoadingButton
                  onClick={handleSave}
                  loadingPosition="start"
                  startIcon={<SaveIcon />}
                  variant="contained"
                  disabled={!selectedBoat || !startDate || !selectedBoatDriver}
                  sx={{ mt: 1, mr: 1 }}
                >
                  Anlegen
                </LoadingButton>
              </Stack>
            </Box>
          </Dialog>
        </>
      )}
    </>
  );
}
