import React from "react";
import MenuBar from "../components/AppBar/MenuBar";
import CreateJourney from "../components/Journey/CreateJourney";
import JourneySummary from "../components/Journey/JourneySummary";
import { IJourney } from "../_interfaces/journey.interface";
import { getOngoingJourneyByDriverId } from "../_services/journey.service";
import { getUser } from "../_services";
import { Box, CircularProgress } from "@mui/material";

export default function Home() {
  const [loading, setLoading] = React.useState<boolean>(true);
  const [ongoingJourneyByDriver, setOngoingJourneyByDriver] = React.useState<IJourney | null>(null);
  const handleJourneyCreated = () => {
    getOngoingJourney();
  };

  const getOngoingJourney = () => {
    setLoading(true);
    const user = getUser();
    if (user) {
      getOngoingJourneyByDriverId(user.id).then((res) => {
        if (res) setOngoingJourneyByDriver(res);
        setTimeout(() => {
          setLoading(false);
        }, 200);
      });
    }
  };

  React.useEffect(() => {
    getOngoingJourney();
  }, []);

  return (
    <>
      <MenuBar />
      {loading ? (
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "80vh" }}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <JourneySummary journey={ongoingJourneyByDriver} />
          <CreateJourney onJourneyCreated={handleJourneyCreated} ongoingJourney={!!ongoingJourneyByDriver} />
        </>
      )}
    </>
  );
}
